<template>
  <div>
    <stage class="edit-stage flix-form-group" />
    <saveBtn v-if="checkData()" class="saveBtn" :callback="setSave" />
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  extends: def,
  components: {
    stage() {
      return import("./stage.vue");
    }
  },
  methods: {
    nextStep() {
      this.$router.push({ name: "survey" });
    }
  }
};
</script>
<style lang="sass" scoped>
.edit-stage
  border: 1px solid grey
  padding: 10px
</style>
